<template>
  <div>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <v-card outlined>
      <v-card-title class="flex-center">
        <span class="step_text" :class="{ active: step >= 1 }">立即儲值</span>

        <v-icon class="mx-2">mdi-arrow-right</v-icon>

        <span class="step_text" :class="{ active: step >= 2 }">匯款資訊</span>
      </v-card-title>
      <v-card-text class="flex-center">
        <v-window
          v-model="step"
          :class="$vuetify.breakpoint.smAndDown ? 'w100p' : 'w80p'"
        >
          <v-window-item :value="1" class="py-4 px-1">
            <v-card>
              <v-card-text>
                <v-form ref="form">
                  <c-autocomplete
                    rounded
                    label="支付方式"
                    v-model="charge.channel_type"
                    :rules="[$rules.required]"
                    :api="getCollectChannelTypes"
                    default="bank_transfer"
                    keyName="code"
                    outlined
                  ></c-autocomplete>
                  <v-text-field
                    rounded
                    label="儲值金額"
                    v-model.number="charge.amount"
                    :rules="[$rules.required]"
                    outlined
                    class="remove-append-margin"
                  >
                    <template #append v-if="!$vuetify.breakpoint.smAndDown">
                      <v-btn rounded @click="addAmount(100)">+100</v-btn>
                      <div class="mr-1"></div>
                      <v-btn rounded @click="addAmount(1000)">+1000</v-btn>
                      <div class="mr-1"></div>
                      <v-btn rounded @click="addAmount(10000)">+10000</v-btn>
                    </template>
                  </v-text-field>

                  <v-text-field
                    rounded
                    label="付款人姓名"
                    v-model="charge.payer_name"
                    :rules="[$rules.required]"
                    outlined
                  ></v-text-field>

                  <v-text-field
                    rounded
                    label="備註"
                    v-model="charge.note"
                    outlined
                  ></v-text-field>
                </v-form>
              </v-card-text>

              <v-card-actions class="flex-center">
                <v-btn
                  rounded
                  color="primary"
                  width="320"
                  @click="doCharge"
                  :disabled="isUnavailabel"
                  :loading="isCecking"
                >
                  儲值
                </v-btn>
              </v-card-actions>
              <SizeBox height="24" />
            </v-card>
          </v-window-item>

          <v-window-item :value="2" class="py-4 px-1">
            <v-card>
              <v-card-text>
                <v-alert
                  border="left"
                  colored-border
                  elevation="3"
                  color="primary"
                >
                  有效期限 : {{ payingState.expired_at | formatTime }}
                </v-alert>

                <v-text-field
                  rounded
                  label="單號"
                  :value="payingState.order_no"
                  readonly
                  outlined
                  class="remove-append-margin"
                >
                  <template #append>
                    <v-btn rounded @click="doCopy(payingState.order_no)">
                      複製
                    </v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  rounded
                  label="收款人姓名"
                  :value="payingState.bank_holder"
                  readonly
                  outlined
                  class="remove-append-margin"
                >
                  <template #append>
                    <v-btn rounded @click="doCopy(payingState.bank_holder)">
                      複製
                    </v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  rounded
                  label="付款人姓名"
                  :value="charge.payer_name"
                  readonly
                  outlined
                  class="remove-append-margin"
                >
                  <template #append>
                    <v-btn rounded @click="doCopy(charge.payer_name)">
                      複製
                    </v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  rounded
                  label="卡號"
                  :value="payingState.card_numder"
                  readonly
                  outlined
                  class="remove-append-margin"
                >
                  <template #append>
                    <v-btn rounded @click="doCopy(payingState.card_numder)">
                      複製
                    </v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  rounded
                  label="銀行"
                  :value="payingState.bank_name"
                  readonly
                  outlined
                  class="remove-append-margin"
                >
                  <template #append>
                    <v-btn rounded @click="doCopy(payingState.bank_name)">
                      複製
                    </v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  rounded
                  label="金額"
                  :value="payingState.amount"
                  readonly
                  outlined
                  class="remove-append-margin"
                >
                  <template #append>
                    <v-btn rounded @click="doCopy(payingState.amount)">
                      複製
                    </v-btn>
                  </template>
                </v-text-field>

                <v-text-field
                  rounded
                  label="應付金額"
                  :value="payingState.verify_amount"
                  readonly
                  outlined
                  class="remove-append-margin"
                >
                  <template #append>
                    <v-btn rounded @click="doCopy(payingState.verify_amount)">
                      複製
                    </v-btn>
                  </template>
                </v-text-field>

                <p class="title">溫馨提醒</p>
                <ul>
                  <li>
                    本匯款資訊僅限本次使用不需記憶，超過時限或打款完成即失效。
                  </li>
                  <li>
                    請確認提單姓名與實際匯款戶名是否符合，否則儲值將無法自動到帳。
                  </li>
                  <li>
                    請使用【手機銀行】，並選擇【實時到賬】，避免延遲到帳。
                  </li>
                  <li>請勿修改金額、延遲支付，造成損失自行承擔。</li>
                </ul>
              </v-card-text>

              <v-card-actions class="flex-center">
                <v-btn rounded color="primary" width="320" @click="doBack">
                  返回
                </v-btn>
              </v-card-actions>
              <SizeBox height="24" />
            </v-card>
          </v-window-item>
        </v-window>
      </v-card-text>
      <SizeBox height="32" />
    </v-card>
  </div>
</template>

<script>
import { getCollectChannelTypes } from '@/api/collectChannels';
import { placeOrder } from '@/api/merchantCenter/collectOrders';
import { getAvailableCollectChannels } from '@/api/merchantCenter/merchants';

export default {
  data() {
    return {
      getCollectChannelTypes,
      isUnavailabel: false,
      isCecking: false,
      step: 1,
      charge: {
        channel_type: '',
        amount: 0,
        payer_name: '',
        note: ''
      },
      payingState: {
        expired_at: '',
        order_no: '',
        bank_holder: '',
        card_numder: '',
        bank_name: '',
        amount: 0,
        verify_amount: 0
      }
    };
  },

  watch: {
    step(v) {
      if (v === 1) {
        this.reset();
      }
    },
    $route() {
      this.reset();
    },
    isUnavailabel(v) {
      v &&
        this.$toast.error('通道未開啟或尚未建立', {
          timeout: false
        });
    }
  },

  created() {
    this.checkCanPlaceOrder();
  },

  methods: {
    async checkCanPlaceOrder() {
      this.isCecking = true;
      const Channel = await getAvailableCollectChannels();

      if (Channel.error || (Array.isArray(Channel) && !Channel.length)) {
        this.isUnavailabel = true;
      }

      this.isCecking = false;
    },

    addAmount(v) {
      this.charge.amount += v;
    },
    reset() {
      this.charge = {
        channel_type: '',
        amount: 0,
        note: ''
      };
      this.payingState = {
        expired_at: '',
        order_no: '',
        bank_holder: '',
        card_numder: '',
        bank_name: '',
        amount: 0,
        verify_amount: 0
      };
    },
    async doCharge() {
      if (!this.$refs.form.validate()) return;
      const { bankDetails, collectOrder, error } = await placeOrder(
        this.charge
      );
      if (!error) {
        this.step = 2;
        this.payingState = {
          expired_at: collectOrder.expired_at,
          order_no: collectOrder.platform_order_no,
          bank_holder: bankDetails.name,
          card_numder: bankDetails.bank_card_no,
          bank_name: bankDetails.bank_name,
          amount: collectOrder.amt,
          verify_amount: collectOrder.verify_amt
        };
      } else {
        this.$toast.error('儲值失敗');
      }
    },
    doBack() {
      this.step = 1;
    },
    doCopy(v) {
      navigator.clipboard.writeText(v);

      this.$toast('已複製');
    }
  }
};
</script>

<style lang="scss" scoped>
.step_text {
  color: $OnSurface400;
  &.active {
    color: $OnSurface900;
  }
}

.remove-append-margin {
  &::v-deep .v-input__append-inner {
    align-self: stretch;
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
</style>
